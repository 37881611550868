import moment from "moment";

function getBusinessName() {
    let bname = sessionStorage.getItem("businessName");
    if (bname) {
        return bname;
    }
}

function jwtDecode(t) {
    let token = {};
    // token.raw = t;
    // token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return (token.payload)
  }

function checkIfValidEmail(email) {
    return /(.+)@(.+){2,}\.(.+){2,}/.test(email);
}

function checkIfStaging() {
    return window.location.href.indexOf('localhost') > -1;
}

function setStorage(key, value) {
    sessionStorage.setItem(key, value);
}

function getStorage(key) {
    return sessionStorage.getItem(key);
}

function setLocalStorage(key, value) {
    localStorage.setItem(key, value);
}

function getLocalStorage(key) {
    return localStorage.getItem(key);
}

// function checkIfValidEmail(email) {
//     var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
//     console.log(email.match(pattern));
//     return email.match(pattern);
// }

function getSelectionStart(o) {
    if (o.createTextRange) {
        var r = document.selection.createRange().duplicate()
        r.moveEnd('character', o.value.length)
        if (r.text === '') return o.value.length
        return o.value.lastIndexOf(r.text)
    } else return o.selectionStart
}

function validateDecimalValue(evt) {
    var el = evt.target;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var number = el.value.split('.');
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    //just one dot (thanks ddlab)
    if (number.length > 1 && charCode === 46) {
        return false;
    }
    //get the carat position
    var caratPos = this.getSelectionStart(el);
    var dotPos = el.value.indexOf(".");
    if (caratPos > dotPos && dotPos > -1 && (number[1].length > 1)) {
        return false;
    }
    return true;
}

function setJsonLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

function getJsonLocalStorage(key) {
    var data = localStorage.getItem(key);
    if (data) {
        // console.log(data);
        return JSON.parse(data);
    } else {
        return {};
    }
}

function getSelectedSchool() {
    var data = localStorage.getItem('selectedSchool');
    if (data) {
        return JSON.parse(data)['id'];
    } else {
        return '-';
    }
}
function getSelectedSchoolLogo() {
    var data = localStorage.getItem('selectedSchoolLogo');
    if (data) {
        console.log(data);
        return JSON.parse(data);
    } else {
        return '-';
    }
}

const formatDate = (date) => {
    return (
        moment(date).format().split('T')[0]
    )
}


const isConfirmed = (question) => {
    return window.confirm(question ? question : 'Are you sure, you want to delete this record?') ? true : false;
}


const CommonUtil = {
    getBusinessName,
    checkIfValidEmail,
    checkIfStaging,
    setStorage,
    getStorage,

    setLocalStorage,
    getLocalStorage,

    validateDecimalValue,
    getSelectionStart,

    setJsonLocalStorage,
    getJsonLocalStorage,

    getSelectedSchool,
    getSelectedSchoolLogo,
    jwtDecode,

    formatDate,
    isConfirmed
}

export default CommonUtil;