import CommonUtil from "../shared/CommonUtil";
import apiService from "./apiService";

export function uploadFile(payload, module_id) {
    var formdata = new FormData();
    formdata.append(payload.type, payload.file)
    return apiService({
        url: "/file",
        data: formdata,
        method: 'post',
        headers: {
            "Content-Type": "form-data"
          },
        Authorization: 'Bearer ' + CommonUtil.getLocalStorage('access_token'),
        isFormData:true,
        module_id
    });
}

export function getFile(fileId, module_id) {
    return apiService({
        url: "/file/"+fileId,
        method: 'get',
        Authorization: 'Bearer ' + CommonUtil.getLocalStorage('access_token'),
        module_id
    });
}

export function encrypt(id) {
    return apiService({
        url: "/attendance/encrypt/" + id,
        method: 'GET',
        content_type: 'text/html',
    });
}

export function getDaysAPI() {
    return apiService({
        url: `/day`,
        param : {},
        method: 'GET'
    });
}
