import apiService from "./apiService";
import CommonUtil from "../shared/CommonUtil";




export function getEmployeeByIdAPI(id,module_id) {
    return apiService({
        url: `/employee/get/${id}`,
        params: {},
        method: 'GET',
        module_id
    })
}
export function updateEmployeeByIdAPI(id, payload,module_id) {
    return apiService({
        url: `/employee/${id}`,
        data: payload,
        method: 'PATCH',
        module_id
    })
}


export function addEmployeeAPI(payload, module_id) {
    payload["schoolId"] = CommonUtil.getSelectedSchool()
    return apiService({
        url: `/employee`,
        data: payload,
        method: 'POST',
        module_id
    })
}
export function importEmployeeAPI(payload, module_id) {
    var formdata = new FormData();
    formdata.append(payload.type, payload.file)
    formdata.append("classId", payload.classId)
    return apiService({
        url: `/employee/import`,
        data: formdata,
        method: 'POST',
        headers: {
            "Content-Type": "form-data"
        },
        isFormData: true,
        module_id
    });
}
export function deleteEmployeeAPI(id, module_id) {
    return apiService({
        url: `/employee/${id}`,
        method: 'DELETE',
        module_id
    })
}

export function getEmployeesAPI(params, module_id) {
    return apiService({
        url: `/employee/${CommonUtil.getSelectedSchool()}`,
        params : params || {},
        method: 'GET',
        module_id
    })
}
export function addDepartmentAPI(name, module_id) {
    return apiService({
        url: `/addDepartment`,
        data: {
            "name": name,
            "schoolId": CommonUtil.getSelectedSchool()
        },
        method: 'POST',
        module_id
    })
}

export function addDesignationAPI(name, module_id) {
    return apiService({
        url: `/addDesignation`,
        data: {
            "name": name,
            "schoolId": CommonUtil.getSelectedSchool()

        },
        method: 'POST',
        module_id
    })
}

export function addEmployeeTypeAPI(name, module_id) {
    return apiService({
        url: `/addEmployeeType`,
        data: {
            "employeeType": name,
            "schoolId": CommonUtil.getSelectedSchool()

        },
        method: 'POST',
        module_id
    })
}
export function getEmployeeTypesAPI(module_id) {
    return apiService({
        url: `/addEmployeeType/${CommonUtil.getSelectedSchool()}`,
        method: 'GET',
        module_id
    })
}

export function getDepartmentsAPI(module_id) {
    return apiService({
        url: `/addDepartment/${CommonUtil.getSelectedSchool()}`,
        method: 'GET',
        module_id
    })
}

export function getDesignationsAPI(module_id) {
    return apiService({
        url: `/addDesignation/${CommonUtil.getSelectedSchool()}`,
        method: 'GET',
        module_id
    })
}

export function deleteDesignationAPI(id, module_id) {
    return apiService({
        url: `/addDesignation/${id}`,
        method: 'DELETE',
        module_id
    })
}

export function deleteDepartmentAPI(id, module_id) {
    return apiService({
        url: `/addDepartment/${id}`,
        method: 'DELETE',
        module_id
    })
}

export function deleteEmployeeTypeAPI(id, module_id) {
    return apiService({
        url: `/addEmployeeType/${id}`,
        method: 'DELETE',
        module_id
    })
}

export function updateEmployeeTypeAPI(id, name, module_id) {
    return apiService({
        url: `/addEmployeeType/${id}`,
        data: {
            "employeeType": name,
            "schoolId": CommonUtil.getSelectedSchool()

        },
        method: 'PATCH',
        module_id
    })
}

export function updateDepartmentAPI(id, name, module_id) {
    return apiService({
        url: `/addDepartment/${id}`,
        data: {
            "name": name,
        },
        method: 'PATCH',
        module_id
    })
}

export function updateDesignationAPI(id, name, module_id) {
    return apiService({
        url: `/addDesignation/${id}`,
        data: {
            "name": name,
        },
        method: 'PATCH',
        module_id
    })
}


export function getLeaveTemplatesAPI(module_id) {
    return apiService({
        url: `/leave/${CommonUtil.getSelectedSchool()}`,
        method: 'GET',
        module_id
    })
}
export function getLeaveTemplateByIdAPI(id, module_id) {
    return apiService({
        url: `/leave/get/${id}`,
        method: 'GET',
        module_id
    })
}

export function deleteLeaveTemplateAPI(id, module_id) {
    return apiService({
        url: `/leave/${id}`,
        method: 'DELETE',
        module_id
    })
}

export function addLeaveTemplateAPI(payload, module_id) {
    payload["schoolId"] = CommonUtil.getSelectedSchool()
    return apiService({
        url: `/leave`,
        data: payload,
        method: 'POST',
        module_id
    })
}
export function updateLeaveTemplateAPI(id, payload, module_id) {
    return apiService({
        url: `/leave/${id}`,
        data: payload,
        method: 'PATCH',
        module_id
    })
}

export function getSalaryTempsAPI(module_id) {
    return apiService({
        url: `/addsalary/${CommonUtil.getSelectedSchool()}`,
        method: 'GET',
        module_id
    })
}

export function getSalaryTempByIdAPI(id, module_id) {
    return apiService({
        url: `/addsalary/get/${id}`,
        method: 'GET',
        module_id
    })
}

export function deleteSalaryTemplateAPI(id, module_id) {
    return apiService({
        url: `/addsalary/${id}`,
        method: 'DELETE',
        module_id
    })
}

export function addSalaryTemplateAPI(payload, module_id) {
    payload["schoolId"] = CommonUtil.getSelectedSchool()
    return apiService({
        url: `/addsalary`,
        data: payload,
        method: 'POST',
        module_id
    })
}
export function updateSalaryTemplateAPI(id, payload, module_id) {
    return apiService({
        url: `/addsalary/${id}`,
        data: payload,
        method: 'PATCH',
        module_id
    })
}