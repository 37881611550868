import CommonUtil from "../shared/CommonUtil";
import apiService from "./apiService";

export function getFee(schoolId, classId, module_id) {
    return apiService({
        url: `/fee/school/${schoolId}/class/${classId}`,
        params: {},
        method: 'GET', module_id
    });
}

export function addFee(payload, module_id) {
    return apiService({
        url: "/fee",
        data: payload,
        method: 'post', module_id
    });
}

export function editFee(payload, feeId, module_id) {
    return apiService({
        url: `/fee/${feeId}`,
        data: payload,
        method: 'PATCH', module_id
    });
}

export function deleteFee(feeId, module_id) {
    return apiService({
        url: `/fee/${feeId}`,
        data: {},
        method: 'delete', module_id
    });
}

export function getTag(schoolId, module_id) {
    return apiService({
        url: `/tag/school/${schoolId}`,
        params: "",
        method: 'get', module_id
    });
}

export function getReceipt(paymentId, module_id) {
    return apiService({
        url: `/payment/${paymentId}.pdf/receipt`,
        params: {},
        method: 'get', module_id
    });
}

export function getReport(payld, module_id, content_type) {
    return apiService({
        url: `/fee/report`,
        params: payld,
        method: 'get', module_id,
        content_type 
    });
}

export function collectFee(payload, module_id) {
    return apiService({
        url: "/payment",
        data: payload,
        method: 'post', module_id
    });
}

export function sendRemainder(studentId, type, module_id) {
    return apiService({
        url: `/student/${studentId}/fees-reminder?type=${type}`,
        data: {},
        method: 'POST',
        Authorization: 'Bearer ' + CommonUtil.getLocalStorage('access_token'), module_id
    });
}

